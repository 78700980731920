<template>
  <div class="UserData">
    <!-- NAVIGATION -->
    <div class="UserData__navigation row row--align-center">
      <router-link
        v-for="(route, index) in routes"
        :key="index"
        :to="{ name: route.path }"
        active-class="UserData__navigation-item--active fc-white"
        class="UserData__navigation-item fc-mid fs-15 capitalize"
        tag="a"
      >
        {{ route.text }}
      </router-link>
    </div>

    <router-view class="UserData__router-view" />
  </div>
</template>

<script>

export default {
  name: 'UserData',

  data () {
    return {
      routes: [
        {
          path: 'debtor-data-general',
          text: 'general',
        },
        {
          path: 'debtor-data-dilution',
          text: 'dilution',
        },
      ],
    }
  },
}
</script>

<style lang="sass">
@import '../../../../styles/user-profile-navigation.sass'
@import '../../../../styles/user-data.sass'
</style>